// Unpublished Work © 2020-2023 Deere & Company.

import {
    StatusCodes
} from 'http-status-codes';

export const ErrorFallbackMessage = 'Error: No error set';

export async function handleResponse<T>(response: Response, customError?: string): Promise<T> {
    const result = await response.json();

    if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
            location.reload();
        }
        throw new Error(result.message || customError || ErrorFallbackMessage);
    }

    return result;
}
