// Unpublished Work © 2020-2023 Deere & Company.

import {handleResponse} from '../../common/utils/response';
import {parseError} from '../utils/error-parser';

export interface PostErrorMetadata {
    url?: string;
    lineNumber?: number;
    columnNumber?: number;
}

export async function postError(
    event: Event | string,
    error: Error | undefined,
    metadata: PostErrorMetadata = {}
): Promise<void> {
    const errorStack = await parseError(error);
    const response = await fetch('/error', {
        method: 'POST',
        body: JSON.stringify({
            error: errorStack,
            metadata,
            message: event
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await handleResponse<void>(response);
}
