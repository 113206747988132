// Unpublished Work © 2024 Deere & Company.

import * as icons from '@deere/icons';

type IconHandler = (parentProps: Partial<{}>) => React.ReactElement;

export const IconsToPreload: [IconHandler, string][] = [
    [icons.IconLoading, 'icon-loading'],
    [icons.IconAlertRed, 'icon-alert-red'],
    [icons.IconGuidanceAutopath, 'icon-guidance-autopath'],
    [icons.IconChevronLeft, 'icon-chevron-left'],
    [icons.IconStar, 'icon-star'],
    [icons.IconStarEmpty, 'icon-star-empty'],
    [icons.IconGuidanceAutopathRows, 'icon-guidance-autopath-rows'],
    [icons.IconGuidanceAutopathBoundaries, 'icon-guidance-autopath-boundaries'],
    [icons.IconGuidanceAbcurve, 'icon-guidance-abcurve'],
    [icons.IconGuidanceAb, 'icon-guidance-ab'],
    [icons.IconGuidanceCircleLatLon, 'icon-guidance-circle-lat-lon'],
    [icons.IconGuidanceAdaptivecurve, 'icon-guidance-adaptivecurve'],
    [icons.IconAdd, 'icon-add'],
    [icons.IconTableSettings, 'icon-table-settings'],
    [icons.IconCircleClose, 'icon-circle-close'],
    [icons.IconCheckGreen, 'icon-check-green'],
    [icons.IconCircleAddSolid, 'icon-circle-add-solid'],
    [icons.IconDryBlend, 'icon-dry-blend'],
    [icons.IconNotSent, 'icon-not-sent'],
    [icons.IconFileReady, 'icon-file-ready'],
    [icons.IconModified, 'icon-modified'],
    [icons.IconImportPartial, 'icon-import-partial'],
    [icons.IconImport, 'icon-import'],
    [icons.IconContributedPrescription, 'icon-contributed-prescription'],
    [icons.IconInfoAlert, 'icon-info-alert'],
    [icons.IconEdit, 'icon-edit'],
    [icons.IconChevronUp, 'icon-chevron-up'],
    [icons.IconChevronDown, 'icon-chevron-down'],
    [icons.IconChevronRight, 'icon-chevron-right'],
    [icons.IconUndo, 'icon-undo'],
    [icons.IconOrderable, 'icon-orderable'],
    [icons.IconNoBoundary, 'icon-no-boundary'],
    [icons.IconAlertYellow, 'icon-alert-yellow'],
    [icons.IconTankMix, 'icon-tank-mix'],
    [icons.IconRemove, 'icon-remove'],
    [icons.IconCheckmark, 'icon-checkmark'],
    [icons.IconAlertEquipment, 'icon-alert-equipment'],
    [icons.IconCalendar, 'icon-calendar'],
    [icons.IconWdt, 'icon-wdt']

];

export function preloadIcons() {
    for (const iconToPreload of IconsToPreload) {
        const [icon, key] = iconToPreload;

        icons.preloadedIcon(icon, key);
    }
}
