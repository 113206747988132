// Unpublished Work © 2020-2022 Deere & Company

import {get} from './config';

export async function checkSession(): Promise<boolean> {
    const response = await fetch('/check-session', {
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Could not fetch the session from server.');
    }
    const json: {
        expired: boolean;
    } = await response.json();

    if (json.expired) {
        throw new Error('Could not fetch the session from server.');
    }

    return true;
}

export function redirectToLoginPage() {
    const currentUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);

    window.location.href = `${get<string>('REACT_APP_SERVER_CORE_URL')}/login?redirectTo=${currentUrl}`;
}
