// Unpublished Work © 2020-2024 Deere & Company.

import fetchIntercept from 'fetch-intercept';
import {preloadIcons} from './App/services/preloadIcons';
import {logUiErrors} from './App/utils/log-ui-errors';
import {get} from './common/utils/config';
import {setLocaleOptions} from './common/utils/locale';
import {checkSession, redirectToLoginPage} from './common/utils/sessions';

window.onerror = logUiErrors;

fetchIntercept.register({
    request(url, conf) {
        let newUrl = url,
            newConf = conf;

        if (!/^(http|https):\/\//.test(url)) {
            newUrl = `${get<string>('REACT_APP_SERVER_CORE_URL')}${url}`;
            newConf = {
                ...conf,
                credentials: conf?.credentials || 'include'
            };
        }

        return [newUrl, newConf];
    }
});

async function setupGoogleMaps() {
    const googleMaps = await import('./Map/utils/googleMaps');

    await googleMaps.initialize();
}

(async () => {
    try {
        setLocaleOptions();

        await checkSession();
        await setupGoogleMaps();

        preloadIcons();

        const appLoader = await import('./AppLoader');

        appLoader.loadApp();
    } catch (error) {
        redirectToLoginPage();
    }
})();
