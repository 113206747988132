// Unpublished Work © 2020-2022 Deere & Company

export type LocaleOptions = {
    decimal: string;
    thousands: string;
    format: (value: number) => string;
};

let localOptions: LocaleOptions;

export const getLocaleOptions = () => localOptions;

export const setLocaleOptions = () => {
    const numberToFormat = 9999999.9;
    const decimalIndex = 9;
    const {format} = new Intl.NumberFormat(window.navigator.language, {
        maximumFractionDigits: 10
    });
    const formattedValue = format(numberToFormat);
    const thousands = formattedValue.charAt(1);
    const decimal = formattedValue.charAt(decimalIndex);

    localOptions = {
        thousands,
        decimal,
        format
    };
};
