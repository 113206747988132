// Unpublished Work © 2020-2023 Deere & Company.

import {postError} from '../services/error';

// eslint-disable-next-line max-params
export const logUiErrors: OnErrorEventHandlerNonNull = (event, source, lineno, colno, error) => {
    if (typeof event === 'string' && event.includes('ResizeObserver')) {
        return false;
    }

    postError(event, error, {
        url: source,
        lineNumber: lineno,
        columnNumber: colno
    }).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
    });

    return false;
};
