// Unpublished Work © 2020-2022 Deere & Company

import StackTrace from 'stacktrace-js';

function getStackTraceFromStackFrames(errorMessage: string, stackFrames: StackTrace.StackFrame[]): string {
    return stackFrames.reduce((errorStack, stackFrame) => {
        return `${errorStack}    at ${stackFrame.functionName} ${stackFrame.fileName}:${stackFrame.lineNumber}:${stackFrame.columnNumber}\n`;
    }, `Error: ${errorMessage}\n`);
}

export async function parseError(error: Error | undefined): Promise<string> {
    let result: string;

    if (error) {
        const stackFrames = await StackTrace.fromError(error);

        result = getStackTraceFromStackFrames(error.message, stackFrames);
    } else {
        result = 'No error set';
    }

    return result;
}
